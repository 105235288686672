<template>
  <v-container fluid>
    <div v-if="currentService">
      <div class="mb-3">
        <div class="text-h5">{{ currentService.name }}</div>
      </div>
      <ADashboard :modules="menu" />
    </div>
    <div v-else>
      <ALoader />
    </div>
  </v-container>
</template>

<script>
import Breadcrumb from "@/objects/breadcrumb";
import configMenu from "@/config/menu";
import { mapState } from "vuex";
export default {
  computed: mapState({
    services: (state) => state.service.data.services,
    currentService: (state) => state.service.data.currentService,
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    breadcrumb: new Breadcrumb(),
    service: null,
    menu: [],
  }),
  created() {
    var serviceKey = this.$route.params.serviceKey;
    if (serviceKey)
      this.service = this.$_.find(this.services, { key: serviceKey });
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: this.service.name,
      to: {
        name: "PageServiceDashboard",
        params: { serviceKey: this.service.key },
      },
      exact: true,
    });
    var targetMenu = configMenu[this.service.key];
    if (targetMenu) this.menu = this.$_.clone(targetMenu);

    // Process menu data based on services.
    let rolesArray;
    const disabledRolesForHrdc = "taxpod-salesperson";
    switch (this.service.key) {
      case "hrdc":
      case "hrdc_einvoicing":
      case "hrdc_tax_summit":
        // Retrieve auth data roles & store into array.
        rolesArray = this.auth.roles.map((item) => item.slug);
        if (
          (rolesArray.length == 1 && rolesArray[0] == disabledRolesForHrdc) ||
          rolesArray.length == 0
        ) {
          for (const menu of this.menu) {
            menu.isAllowed = true;

            if (
              menu.disabledForRoles &&
              menu.disabledForRoles.includes(disabledRolesForHrdc)
            ) {
              menu.isAllowed = false;

              if (rolesArray.length == 0) {
                menu.isAllowed = false;
              }
            }
          }
        }
        break;
    }
  },
};
</script>
